jQuery(function ($) {

    var options = {
        title: 'Deze website maakt gebruik van cookies',
        message: 'We gebruiken cookies om inhoud en advertenties te personaliseren, om functies voor sociale media te bieden en om verkeer naar onze site te analyseren. We delen ook informatie over uw gebruik van onze website met onze sociale media-, advertentie- en analysepartners. Onze partners kunnen deze informatie combineren met andere informatie die u aan hen verstrekt of die u hebt verzameld terwijl u de Services gebruikt.',
        delay: 600,
        expires: 1,
        link: '/privacybeleid',
        onAccept: function(){
            var myPreferences = $.fn.ihavecookies.cookie();
            console.log('Yay! The following preferences were saved...');
            console.log(myPreferences);
        },
        uncheckBoxes: true,
        acceptBtnLabel: 'Accepteer cookies',
        moreInfoLabel: 'Meer info',
        advancedBtnLabel: 'Cookies wijzigen',
        cookieTypesTitle: 'Selecteer welke cookies u wilt accepteren',
        fixedCookieTypeLabel: 'Essentieel',
        fixedCookieTypeDesc: 'Deze zijn essentieel voor een correcte werking van de website'
    }

    $('body').ihavecookies(options);



    $('#header').overlayScrollbars({
        scrollbars : {
            autoHide: "always",
        },
    });


    $("form :input").focus(function() {
        $("label[for='" + this.id + "']").addClass("label-focus");
    }).blur(function() {
        $("label").removeClass("label-focus");
    });

    $('[data-toggle="tooltip"]').tooltip();



    baseUrl = window.location.protocol + "//" + window.location.host;

    $('input[type="radio"]').change(function () {
        $('input[name="' + this.name + '"]').not(this).parent().removeClass('checked');
        $(this).parent().toggleClass('checked', this.checked)
    });

    $('.orientation input').bind('input', function() {
        inputValue = $(this).val();
        labelText = ($(this).data('text'));
        $('.orientation-image img').fadeOut(300, function() {
            $(this).attr('src', baseUrl + "/assets/img/offer/" + inputValue + '.svg');
            $(this).attr('alt', labelText);
            $('.orientation-image h4').text(labelText);
        }).fadeIn(300);
    });


    $('.mobile-toggle').click(function(){
       $(this).toggleClass('is-active');
       $('.main-header').toggleClass('is-open');
       $('body').toggleClass('is-open');
    });

    wayPoints();
    $('.heading-title').append('<span class="line"></span>');
});

function wayPoints() {
    setTimeout(function () {
        $('.hero figure').addClass('loaded')
    }, 1000);

    var waypoints = $('.what').waypoint({
        handler: function (direction) {
            $(this['element']).addClass("on-screen");
        },
        offset: '20%'
    });

    var waypoints = $('.heading-title').waypoint({
        handler: function (direction) {
            $(this['element']).addClass("on-screen");
        },
        offset: '70%'
    });
}

function setActiveMenuItem() {
    var path = window.location.pathname.split("/").pop();

    // Account for home page with empty path
    if (path === '/') {
        path = '/';
    }

    var target = $('nav ul li a[href*="' + path + '"], ul.slicknav_nav li a[href*="' + path + '"]');
    // Add active class to target link
    target.addClass('active');
}

function initMap() {
    var styledMapType = new google.maps.StyledMapType([
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]);


    var myLatLng = {lat: 50.9629712, lng: 5.500987};
    var map = new google.maps.Map(document.getElementById('map_canvas'), {
        zoom: 14,
        disableDefaultUI: true,
        center: myLatLng,
        mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain',
                'styled_map']
        },
        scrollwheel: false
    });
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    var iconBase = 'https://enium.be/assets/img/pin.png';

    var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        icon: iconBase
    });

    $("#back").click(function () {
        $("html, body").animate({scrollTop: 0}, "slow");

        return false;

    });
}
